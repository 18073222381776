<template>
	<span v-if="value">
		<span
			v-for="(el, index) in visibleValues"
			:key="index"
		>
			<button
				v-if="isFilter"
				type="button"
				@click="changeFilter(slug, el, true)"
			>{{ el }}</button>
			<template v-else>{{ el }}</template>
			<template v-if="index < values.length - 1">,&nbsp;</template>
		</span>
		<template v-if="hasLimit">
			<br>
			<button
				type="button"
				@click="limit = values.length"
			>
				...
			</button>
		</template>
	</span>
</template>

<script>
import {FilterMixin} from '@/mixins';


export default {
	mixins: [FilterMixin],
	props: {
		name: {
			type: String,
			required: true
		},
		slug: {
			type: String,
			required: true
		},
		value: {
			type: [Array, Number, String],
			default: ''
		},
		isFilter: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			limit: 3
		};
	},
	computed: {
		hasLimit() {
			const {limit, values} = this;
			return values.length > limit + 1;
		},
		values() {
			const {value} = this;
			return (Array.isArray(value) ? value : [value]);
		},
		visibleValues() {
			const {hasLimit, limit, values} = this;
			return (hasLimit) ? values.slice(0, limit) : values;
		}
	}
};
</script>

<style lang="scss" scoped>
	button {
		@include button-reset();
		cursor: pointer;
		display: inline;
		padding: 0;
		color: $color-link;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	span > span:not(:only-child) {
		display: inline-block;
		white-space: nowrap;
	}
</style>
