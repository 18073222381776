import {EventBus} from './event-bus';


export const
	FilterMixin = {
		methods: {
			changeFilter(type, value, reset) {
				// Reset all filters
				if (reset) {
					this.$store.dispatch('resetFilters');
					EventBus.$emit('Modal:close');
				}

				this.$store.dispatch('addFilter', {type, value});
			}
		}
	},

	ModalMixin = {
		methods: {
			onOpenModal(e) {
				EventBus.$emit('Modal:open', e);
			}
		}
	},

	MoreMixin = {
		data() {
			return {
				limit: 12
			};
		},
		computed: {
			items() {
				return this.$store.getters.animals;
			},
			visibleItems() {
				return this.items.slice(0, this.limit);
			}
		},
		watch: {
			items() {
				this.limit = this.$options.data().limit;
			}
		},
		methods: {
			more() {
				const
					summand = this.$options.data().limit * 2,
					sum = this.limit + summand,
					total = this.items.length
				;

				this.limit = (sum + summand >= total) ? total : sum;
			}
		}
	},

	ScrollMixin = {
		methods: {
			scroll(top) {
				const options = {
					'behavior': 'smooth',
					'left': 0,
					'top': top || 0
				};

				if ('scrollBehavior' in document.documentElement.style) {
					window.scrollTo(options);
				} else {
					window.scrollTo(options.left, options.top);
				}
			}
		}
	},

	ToggleMixin = {
		data() {
			return {
				toggle: true
			};
		},
		methods: {
			onClick() {
				this.toggle = !this.toggle;
			}
		}
	}
;
